import { motion, AnimatePresence } from 'framer-motion'

function Wrap({ d, is, i }) {
  return (
    <AnimatePresence>
      {is && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, delay: i * 0.1 }}
        >
          {d}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Wrap
