import { useEffect, useState, useContext, useRef } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import anime from 'animejs'
import { c } from '../media/colors.js'
import './head.css'

const Head = () => {
  return (
    <>
      <header>
        <Link to={'/'}>
          <svg viewBox='0 0 64 64'>
            <g fill={'none'}></g>
          </svg>
        </Link>
      </header>
      <main>
        <div className={'grid'}>
          {[...Array(600).keys()].map((d) => (
            <div>
              <div></div>
            </div>
          ))}
        </div>
        <Outlet />
      </main>
      <footer></footer>
    </>
  )
}

export default Head
