import { useEffect, useState, useContext, useMemo } from 'react'
import { Select, Group, Button } from '@mantine/core'
import { Dropzone, DropzoneStatus, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import _ from 'lodash'
import { motion } from 'framer-motion'

function Upload({ form }) {
  return (
    <motion.div className={'c-1'}>
      <input
        type='file'
        id='myFile'
        name='filename'
        {...form.getInputProps('upload')}
      />
    </motion.div>
  )
}

export default Upload
