import { useEffect, useState, useContext, useMemo } from 'react'
import { Select, Group, Button } from '@mantine/core'

import _ from 'lodash'
import data from './data/histograms.json'
import { motion } from 'framer-motion'

function Dropdown({ form, setHist }) {
  return (
    <motion.div className={'c-1'}>
      <div className={'c-1-head'}>select from my data:</div>
      <Select
        className={'c-1-drop'}
        onSelect={(e) => setHist(form.values.histogram)}
        data={_.keys(data)}
        placeholder='search'
        dropdownPosition={'bottom'}
        searchable
        clearable
        nothingFound='Nothing found'
        {...form.getInputProps('histogram')}
        styles={{
          input: {
            color: '#EE791F',
            border: 'none',
            borderRadius: 0,
            borderBottom: '4px solid gray',
          },
          item: {
            lineHeight: '32px',
          },
          selected: { height: 50 },
        }}
      />
    </motion.div>
  )
}

export default Dropdown
