import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { c } from '../media/colors.js'
import { Theme } from '../../context/theme'
import MDCLogo from '../media/mdc_logo.svg'
import Cycle from '../media/cycle.svg'

import Grain0 from '../media/grain0.png'
import * as d3 from 'd3'

import { motion, useViewportScroll, useTransform } from 'framer-motion'

import './home.css'

function Home() {
  const { scrollYProgress } = useViewportScroll()
  return (
    <motion.div
      className={'home'}
      style={{ background: c.wht0 }}
      exit={{ x: '-100vw' }}
      transition={{ duration: 1 }}
    >
      {[...Array(3).keys()].map((d, i) => (
        <motion.div
          className={'gradient gradient' + (i + 1)}
          style={{
            originX: 0,
          }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 1 - i * 0.1, delay: i * 0.2 }}
          custom={i}
        />
      ))}
      <motion.h1
        className={'logo'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 1 }}
      >
        my
        <span className={'dot'}>&#183;</span>
        data<span className={'dot'}>&#183;</span>can
      </motion.h1>
      <motion.h2
        className={'manufacture'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 1 }}
      >
        manufacturing
      </motion.h2>
      <motion.svg className={'main'} width='100vw' height='100vh'>
        {[...Array(0).keys()].map((d, i) => {
          const rand = Math.random()
          const turn = (n) => {
            const skip = Math.random() > 0.5 ? n + 1 : n
            return 7.5 + 5 * skip + 'vw'
          }
          return (
            <motion.circle
              style={{ background: c.blk0 }}
              r={6}
              cx={'7.5vw'}
              cy={'7.5vw'}
              animate={{
                cx: ['7.5vw', turn(2), turn(3), turn(4)],
                cy: ['7.5vw', turn(2), turn(3), turn(4)],
              }}
              transition={{ duration: 2, times: [0, 0.2, 0.7, 1] }}
            />
          )
        })}
        <motion.circle
          style={{
            fill: 'none',
            strokeWidth: '5vw',
            stroke: '#8CA2BA',
          }}
          r={'2.5vw'}
          cx={'87.5vw'}
          cy={'12.5vw'}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1.1 }}
          transition={{ duration: 0.5 }}
        />
        <motion.circle
          style={{
            fill: 'none',
            strokeWidth: '5vw',
            stroke: '#416A96',
          }}
          r={'2.5vw'}
          cx={'87.5vw'}
          cy={'12.5vw'}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 0.3 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        />
      </motion.svg>
      <Link to={'/profile'}>
        <motion.div
          className={'login'}
          style={{ originX: 0.5, originY: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 1 }}
          whileTap={{
            scale: 0.9,
            transition: { duration: 0.2 },
          }}
        >
          <motion.div>login</motion.div>
        </motion.div>
      </Link>
      <motion.div
        className={'desc'}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        A platform to facilitate sensitive data analysis between manufacturers
        and researchers.
      </motion.div>
      <motion.img className={'cycle'} src={Cycle} />
      <motion.svg className={'flow'} width='100vw' height='15vw'>
        <text x={'7.5vw'} y={'9.4vw'}>
          manufacturer
        </text>
        <motion.circle cx={'50vw'} cy={'7.5vw'} r={'5vw'} />
        <text x={'92.5vw'} y={'9.4vw'} style={{ textAnchor: 'end' }}>
          researcher
        </text>
        {[...Array(1000).keys()].map((d, i) => {
          return (
            <motion.circle
              cy={d3.randomUniform(3, 12)() + 'vw'}
              initial={{ cx: d3.randomUniform(7.5, 27.5)() + 'vw' }}
              animate={{
                cx: d3.randomUniform(45, 55)() + 'vw',
                opacity: [0, 1, 1, 0],
              }}
              transition={{
                duration: d3.randomUniform(0.2, 3)(),
                delay: d3.randomUniform(4, 25)(),
                ease: 'easeOut',
              }}
              r={2}
              fill={'black'}
            />
          )
        })}

        {[...Array(200).keys()].map((d, i) => {
          return (
            <motion.rect
              y={d3.randomUniform(4, 11)() + 'vw'}
              height={1}
              initial={{
                x: d3.randomUniform(70, 80)() + 'vw',
                width: d3.randomUniform(20, 30)() + 'vw',
              }}
              animate={{
                x: d3.randomUniform(45, 55)() + 'vw',
                width: 0,
                opacity: [0, 1, 1, 0],
              }}
              transition={{
                duration: d3.randomUniform(0.2, 3)(),
                delay: d3.randomUniform(4, 25)(),
                ease: 'easeIn',
              }}
              fill={'black'}
            />
          )
        })}
      </motion.svg>
      <motion.div
        className={'flow-desc'}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        Manufacturers submit data to be analyzed by researchers' formulas, each
        party being shielded from the others sensitive information.
      </motion.div>
    </motion.div>
  )
}

export default Home
