import { useEffect, useState, useContext, useMemo } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import './site/main.css'
import { Theme } from './context/theme'
import Home from './site/home/home'
import Head from './site/head/head'
import Profile from './site/profile/profile'
import { AnimatePresence } from 'framer-motion'

const App = () => {
  const location = useLocation()
  return (
    <>
      <Theme.Provider value={[]}>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Head />}>
              <Route index element={<Home />} />
              <Route path='/profile' element={<Profile />} />

              {/* <Route path='/:id' element={<Dish />} />
              <Route path='/inquiry' element={<Inquiry />} /> */}
            </Route>
          </Routes>
        </AnimatePresence>
      </Theme.Provider>
    </>
  )
}

export default App
