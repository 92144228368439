import { useEffect, useState, useContext, useMemo } from 'react'
import { c } from '../media/colors.js'
import { Theme } from '../../context/theme'
import { TextInput, Checkbox, Button, Group, Box } from '@mantine/core'
import { useForm } from '@mantine/form'
import Upload from './io/upload'
import Dropdown from './io/dropdown'
import Histogram from './io/histogram'
import Scatter from './io/scatter'
import Wrap from './io/wrap'
import Soon from './io/soon'

import Fructose from '../media/fructose.png'
import Flashlight from '../media/flashlight.svg'
import Brain from '../media/brain.svg'
import Glass from '../media/glass.svg'

import { motion } from 'framer-motion'

import './profile.css'

function Profile() {
  const [hist, setHist] = useState(null)
  const [is, setIs] = useState({
    fructose: 0,
    feature: 0,
    anomaly: 0,
    forecasting: 0,
  })
  const form = useForm({
    initialValues: {},
  })

  return (
    <form onSubmit={form.onSubmit((values) => console.log(values))}>
      <motion.div
        className={'home'}
        style={{ background: c.wht0 }}
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '100vw' }}
        transition={{ duration: 1 }}
      >
        <motion.div className={'c-flex'}>
          <motion.div className={'head'}>
            <motion.h1>
              my<span className={'dot'}>&#183;</span>apps
            </motion.h1>
          </motion.div>

          <motion.div className={'tool'}>
            <motion.div
              className={'tool-head'}
              onClick={() => setIs({ ...is, fructose: !is.fructose })}
            >
              <motion.img className={'tool-head clip-path'} src={Fructose} />

              <motion.h2>Crystal Counter</motion.h2>
              <motion.h3>
                Analyzes images of crystal structures for food and chemical
                processing and reports distributions of crystal counts and sizes
                to distinguish between "good" and "bad" runs for a product. This
                tool performs statistical analysis on images in a secure manner
                that does not share your data.
              </motion.h3>
            </motion.div>
            <motion.h4>
              Qiyue Liang, Jan Allebach, Ashraf Alam, Elisa Bertino, Ali
              Shakouri, Ted Fiock and Harris Mousoulis. Purdue University.
            </motion.h4>
            <motion.div className={'tool-main'}>
              {[
                <Dropdown form={form} setHist={setHist} />,
                <Histogram hist={hist} />,
              ].map((d, i) => {
                return <Wrap d={d} is={is.fructose} i={i} />
              })}
            </motion.div>
          </motion.div>

          <motion.div className={'tool'}>
            <motion.div
              className={'tool-head'}
              onClick={() => setIs({ ...is, feature: !is.feature })}
            >
              <motion.img className={'tool-head'} src={Flashlight} />
              <motion.h2 style={{ lineHeight: '5vw' }}>
                Feature extraction
              </motion.h2>
              <motion.h3>
                Analyze your data to extract relevant features for your
                use-cases.
              </motion.h3>
            </motion.div>
            <motion.h4></motion.h4>
            <motion.div className={'tool-main'}>
              {[<Upload form={form} />, <Soon />].map((d, i) => {
                return <Wrap d={d} is={is.feature} i={i} />
              })}
            </motion.div>
          </motion.div>

          <motion.div className={'tool'}>
            <motion.div
              className={'tool-head'}
              onClick={() => setIs({ ...is, anomaly: !is.anomaly })}
            >
              <motion.img className={'tool-head'} src={Brain} />

              <motion.h2 style={{ lineHeight: '5vw' }}>
                Anomaly Detection
              </motion.h2>
              <motion.h3>Detect and explore anomalies in your data.</motion.h3>
            </motion.div>
            <motion.h4></motion.h4>
            <motion.div className={'tool-main'}>
              {[<Soon />].map((d, i) => {
                return <Wrap d={d} is={is.anomaly} i={i} />
              })}
            </motion.div>
          </motion.div>

          <motion.div className={'tool'}>
            <motion.div
              className={'tool-head'}
              onClick={() => setIs({ ...is, forecasting: !is.forecasting })}
            >
              <motion.img className={'tool-head'} src={Glass} />

              <motion.h2 style={{ lineHeight: '5vw' }}>Forecasting</motion.h2>
              <motion.h3>
                Use your data to forecast future trends and patterns.
              </motion.h3>
            </motion.div>
            <motion.h4></motion.h4>
            <motion.div className={'tool-main'}>
              {[<Soon />].map((d, i) => {
                return <Wrap d={d} is={is.forecasting} i={i} />
              })}
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </form>
  )
}

export default Profile
