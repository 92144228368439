const c = {
  blk0: '#000',
  wht0: '#FFF',
  light: 1,
  bak0: '#F5F2E9',
  bak1: '#FCFCFC',
  bak2: '#F0EBDE',
  blu0: '#092075',
  blu1: '#7B8ECC',
  blu2: '#818DBA',
  orn0: '#DC5000',
  orn1: '#DC5B11',
  orn2: '#EB9D70',
  grn0: '#115B07',
  grn1: '#146D08',
  grn2: '#A1D39A',
  gry1: '#333',
  txt1: '#444444',
  txt2: '#999999',
  txt3: '#D1D1D1',
  txt4: '#E2DDCF',
  str1: 2,
}

export { c }
