import { useEffect, useState, useContext, useMemo } from 'react'

import { motion, AnimatePresence } from 'framer-motion'

import _ from 'lodash'
import * as d3 from 'd3'

import data from './data/histograms.json'

function Histogram({ hist }) {
  const dots = _(data[hist]).values().value()
  const buckets = _(dots)
    .groupBy()
    .values()
    .map((d) => ({ id: d[0], val: d.length }))
    .value()

  const bin1 = d3.bin()(dots)
  const x = d3.scaleLinear().domain(d3.extent(dots)).range([0, 85])

  const y = d3
    .scaleLinear()
    .domain(d3.extent(bin1, (d) => d.length))
    .range([0, 85])

  const _y = d3
    .scaleLinear()
    .domain(d3.extent(bin1, (d) => d.length))
    .range([85, 0])

  return (
    <motion.div className='viz1'>
      <div className={'yaxis'}>
        <div>crystal count</div>
      </div>
      <motion.svg className={'histogram'}>
        {bin1.map((d, i) => {
          const ext = d3.extent(d)
          return (
            <>
              {/* {i % 2 && (
                <motion.text
                  className={'axis-labels'}
                  style={{ fontFamily: 'Roboto Mono', fontSize: '10px' }}
                  x={x(ext[0]) + 6 + '%'}
                  y={'92%'}
                  fill={'black'}
                >
                  {ext[0]}
                </motion.text>
              )} */}
              <motion.rect
                width={x(ext[1] - ext[0]) + '%'}
                height={y(d.length) + '%'}
                x={x(ext[0]) + 6 + '%'}
                y={85.5 - y(d.length) + '%'}
                fill={'orange'}
              ></motion.rect>
            </>
          )
        })}

        {hist &&
          [...Array(10).keys()].map((d, i) => {
            return (
              <>
                <motion.text
                  className={'axis-labels'}
                  style={{
                    fontFamily: 'Roboto Mono',
                    fontSize: '10px',
                    textAnchor: 'middle',
                  }}
                  x={'4%'}
                  y={_y(d * 200) + '%'}
                  fill={'black'}
                >
                  {d * 200}
                </motion.text>
                <motion.text
                  className={'axis-labels'}
                  style={{
                    fontFamily: 'Roboto Mono',
                    fontSize: '10px',
                    textAnchor: 'middle',
                  }}
                  x={x(d * 50) + 6 + '%'}
                  y={'92%'}
                  fill={'black'}
                >
                  {d * 50}
                </motion.text>
              </>
            )
          })}

        <text x={'50%'} y={'100%'} style={{ textAnchor: 'middle' }}>
          crystal sizes (pixels)
        </text>
      </motion.svg>
    </motion.div>
  )
}

export default Histogram
